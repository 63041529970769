import styled from "styled-components"

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;

  a {
    margin: 0 10px;
  }
`
